import React, { useState } from 'react';
import ContactForm from './ContactForm';
import { GrScheduleNew } from "react-icons/gr";
import Background from '../assets/background3.png';

const Hero = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const toggleContactForm = () => setShowContactForm(!showContactForm);

  return (
    <div className="text-gray-800 bg-gradient-to-b from-white to-[#ffedd5ad] min-h-screen flex flex-col justify-center items-center p-4">
      <div className="text-center">
        <h1 className="font-bold p-2 text-4xl md:text-5xl lg:text-6xl text-[#5b000b]">
          Centre D'hémodialyse<span className="text-[#ffb85b]"> Kilyati</span>
        </h1>
        <button
          className="bg-[#6a1e27] m-2 py-2 px-4 rounded-md shadow-lg text-white text-xl md:text-2xl lg:text-3xl font-bold hover:bg-[#ffc478] hover:text-[#6a1e27] transition-colors duration-300 ease-in-out flex items-center gap-2"
          onClick={toggleContactForm}
        >
          Prendre un Rendez-Vous
          <GrScheduleNew size={30} />
        </button>
        {showContactForm && <ContactForm onClose={() => setShowContactForm(false)} />}
      </div>
      <img src={Background} alt="background" className="max-w-full md:max-w-xl" />
    </div>
  );
};

export default Hero;

