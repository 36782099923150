import React, { useState, useEffect } from "react";
import { Link } from 'react-scroll';
import logoKilyati from '../assets/logoOriginal.jpg'
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineHome
} from "react-icons/ai";
import Contact from "./ContactForm";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
    setNav(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`flex justify-between items-center p-4 w-auto  mx-auto bg-[#ffffff] cursor-ponter ${
        isSticky ? "sticky top-0 shadow-md bg-[#6a1e27] h-16 " : ""
      }`}  style={{ zIndex: 1000 }}
    > 
    <div className="flex justify-center items-center gap-3">
      <img src={logoKilyati} alt="logo kilyati" className="h-[50px]" />
      <h1 className={isSticky ? "text-3xl font-bold text-[#6a1e27] hover:text-[#6a1e27] cursor-pointer ":"text-3xl font-bold text-[#6a1e27] hover:text-[#6a1e27] cursor-pointer" }>
        Kilyati
      </h1>
    </div>
          <ul className="hidden md:flex justify-center items-center space-x-4 text-cyan-50">
          <li className={isSticky ? "p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer ":" p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold"}>
           <Link to="home"><AiOutlineHome   size={29} /></Link>
          
        </li>
        <Link to="about-us" smooth={true} duration={500} className={isSticky ? "p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold":" p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold"}>À propos</Link>
        <Link to="services" smooth={true} duration={500} className={isSticky ? "p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer  font-bold":" p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold"}>Services</Link>
        <Link to="espace" smooth={true} duration={500} className={isSticky ? "p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold":" p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold"}>Gallerie</Link>
        <li
        className={isSticky ? "p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold":" p-4 text-[#6a1e27] hover:text-[#ffc478] cursor-pointer font-bold"}
          onClick={toggleContactForm}
        >
          Contact
        </li>
      </ul>

      <div onClick={handleNav} className="md:hidden z-20">
        {!nav ? <AiOutlineMenu className="text-[#5b000b] cursor-pointer" size={30} /> : <AiOutlineClose size={30} className="text-[#5b000b] cursor-pointer"  />}
      </div>
      {nav && (
        <div className="fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 md:hidden">
          <h1 className="w-full text-3xl font-bold  m-4 text-[#ffc478]">
            Kilyati.
          </h1>
          <ul className="uppercase p-4 flex flex-col">
          <Link to="about-us" smooth={true} duration={500} className="p-4 border-b text-stone-300 border-gray-800 hover:text-[#ffc478]  cursor-pointer font-bold">À propos</Link>
          <Link to="services" smooth={true} duration={500} className="p-4 border-b text-stone-300 border-gray-800 hover:text-[#ffc478] cursor-pointer font-bold ">Services</Link>
          <Link to="espace" smooth={true} duration={500} className="p-4 border-b text-stone-300 border-gray-800 hover:text-[#ffc478] cursor-pointe font-bold">Gallerie</Link>
          <li
            className="p-4 text-stone-300 hover:text-cyan-900 cursor-pointer font-bold"
            onClick={toggleContactForm}
          >
            Contact
          </li>
        </ul>
        
        </div>
      )}
      {showContactForm && <Contact onClose={() => setShowContactForm(false)} />}
    </div>
  );
};

export default Navbar;
