import React, { useEffect } from "react";
import img from "../assets/Equipe.jpg";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import SecondSvg from '../assets/stethoscope-svgrepo-com.svg';

const About = () => {
  // Create animation controls and refs for each paragraph
  const controlsText1 = useAnimation();
  const [refText1, inViewText1] = useInView();

  const controlsText2 = useAnimation();
  const [refText2, inViewText2] = useInView();

  const controlsText3 = useAnimation();
  const [refText3, inViewText3] = useInView();

  const controlsImage = useAnimation();
  const [refImage, inViewImage] = useInView();

  useEffect(() => {
    if (inViewText1) {
      controlsText1.start("visible");
    } else {
      controlsText1.start("hidden");
    }

    if (inViewText2) {
      controlsText2.start("visible");
    } else {
      controlsText2.start("hidden");
    }

    if (inViewText3) {
      controlsText3.start("visible");
    } else {
      controlsText3.start("hidden");
    }

    if (inViewImage) {
      controlsImage.start("visible");
    } else {
      controlsImage.start("hidden");
    }
  }, [controlsText1, inViewText1, controlsText2, inViewText2, controlsText3, inViewText3, controlsImage, inViewImage]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div id="about-us" className="bg-gradient-to-b from-[#ffffff] to-[#ffedd5ad] min-h-screen p-4 flex flex-col lg:flex-row justify-between items-center lg:px-32 px-5 pt-24 lg:pt-16 gap-5">
      <div className="w-full lg:w-3/4 space-y-4 p-9">
        <div className="flex items-center">
          <img src={SecondSvg} className="w-[60px]" alt="" />
          <motion.h1
            className="text-4xl font-bold text-center lg:text-start text-red-950"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            À propos de nous:
          </motion.h1>
        </div>
        
        <motion.p
          ref={refText1}
          initial="hidden"
          animate={controlsText1}
          variants={fadeInUp}
          className="text-justify lg:text-start text-[#70363d] font-bold"
        >
        Le personnel de notre centre d'hémodialyse est bien plus qu'une équipe médicale - nous sommes une famille engagée à prendre soin de la vôtre. Nous comprenons les défis que peut poser la dialyse, tant sur le plan physique que émotionnel, c'est pourquoi nous nous efforçons de créer un environnement où vous vous sentez en sécurité, compris et respecté. Avec notre approche centrée sur le patient, nous visons à vous offrir non seulement les meilleurs soins médicaux, mais aussi le soutien et le réconfort dont vous avez besoin pour vivre pleinement chaque jour.
        </motion.p>

        <motion.p
          ref={refText2}
          initial="hidden"
          animate={controlsText2}
          variants={fadeInUp}
          className="text-justify lg:text-start text-[#70363d] font-bold"
        >
          Notre centre est équipé de Générateurs de marque (NIKKISO, NIPRO et FRESENIUS)...
        </motion.p>

        <motion.p
          ref={refText3}
          initial="hidden"
          animate={controlsText3}
          variants={fadeInUp}
          className="text-justify lg:text-start text-[#70363d] font-bold"
        >
          More details about other facilities and services...
        </motion.p>
      </div>

      <div className="w-full lg:w-3/4">
        <motion.img
          ref={refImage}
          initial="hidden"
          animate={controlsImage}
          variants={fadeInUp}
          className="rounded-lg h-[550px]"
          src={img} 
          alt="img"
        />
      </div>
    </div>
  );
};

export default About;
