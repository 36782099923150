import React from "react";
import { Link } from "react-scroll";
import {AiOutlineGoogle,AiOutlineWhatsApp} from 'react-icons/ai'
import { FaFacebook } from 'react-icons/fa';


const Footer = () => {
  return (
    <div className=" bg-[#6a1e27] rounded-t-3xl mt-8 md:mt-0  pb-1 text-red-50">
      <div className="flex flex-col md:flex-row justify-between p-8 md:px-32 px-5">
        <div className=" w-full md:w-1/4">
          <h1 className="text-2xl pb-4 font-bold text-[#ffc478]">Horaire:</h1>
          <p className=" text-m font-bold">
          Lun – Sam :ㅤ 6H00 –  21H00
          </p>
        </div>
       
        <div>
          <h1 className=" font-bold text-2xl pb-4 pt-5 md:pt-0 text-[#ffc478]">Nos Partenaires</h1>
          <nav className=" flex flex-col gap-2">
            <a
              href="https://maps.app.goo.gl/kWNqKsLPM3CGPMqVA" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-[#ffc478]" rel="noreferrer"
            >
              Centre 44 - Ouled Teima 
            </a>
            <a
              href="https://maps.app.goo.gl/QYseWaBicnq97jvVA" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-[#ffc478]" rel="noreferrer"
            >
              Clinique Du Rein - Biougra
            </a>
            <a
              href="https://essaouira-dialyse.com/" target="_blank"
              className="font-bold hover:text-hoverColor transition-all cursor-pointer hover:text-[#ffc478]" rel="noreferrer"
            >
              Centre International - Essaouira
            </a>

          </nav>
        </div>
        <div className=" w-full md:w-1/4">
          <h1 className=" font-bold text-2xl pb-4 pt-5 md:pt-0 text-[#ffc478]">Contact Us</h1>
          <nav className=" flex flex-col gap-2 font-bold">
            <Link to="/https://dialyse44.com" spy={true} smooth={true} duration={500}>
              N°Avenue Hassan II, Al Amane, Jihadia - Inezgane 80352
            </Link>
            <Link to="/https://cliniquedurein.com" spy={true} smooth={true} duration={500}>
            ckd.rendezvous@gmail.com
            </Link>
            <Link to="/https://essaouira-dialyse.com" spy={true} smooth={true} duration={500} className=" font-bold">
              Fix: 05 28 33 17 02  │  Fax: 05 28 33 42 45 │ 06 74 80 26 58
            </Link>
          </nav>
          <br />
          <div className="flex mt-2 gap-4 items-center">
            <FaFacebook size={30} className="hover:text-[#ffc478] cursor-pointer"/>
            <AiOutlineGoogle size={38} className="hover:text-[#ffc478]  cursor-pointer"/>
            <AiOutlineWhatsApp size={30} className="hover:text-[#ffc478] cursor-pointer"/>
          </div>
        </div>
      </div>
      <div>
        <br />
        <p className=" text-center">
        @copyright 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
