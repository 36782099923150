import React from 'react';
import { FaMapPin ,FaMapMarkedAlt} from 'react-icons/fa';

const Position = () => {
  return (
    <div className='flex flex-col items-center justify-center text-center p-8 pb-4 bg-gradient-to-b from-[#fffaf5] to-[#ffffff]' >
      <div className='flex gap-2'>
        <FaMapPin size={40} className='text-[#6a1e27]' />
        <h1 className='text-2xl font-bold mb-4 shadow-md'>Notre Emplacement:</h1>
      </div>
      <p className='text-[#695d28] font-bold m-2 shadow-sm'>
      Adresse : N°Avenue Hassan II, LOT Al Amane Dcheira Jihadia - inezgane 80352
      </p>
      <div className='w-full md:w-3/4 lg:w-1/2 pb-[40%] relative'>
        <iframe
          className='rounded-lg ring-cyan-300 shadow-xl absolute top-0 left-0 w-full h-full'
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13770.191632843686!2d-9.5255301!3d30.3637959!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3c7ad79b1e2dd%3A0xe3d7c77db96ac5fc!2sCENTRE%20KILYATI%20DE%20DIALYSE%20CKD!5e0!3m2!1sfr!2sma!4v1713792899264!5m2!1sfr!2sma"
          width="70%"
          height="70%"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title='Responsive Google map'
        />
      </div>
      <div className='flex justify-center m-3 rounded-lg bg-[#6a1e27] w-[300px] shadow-lg my-6 p-2 text-white text-[20px] font-bold hover:text-[#6a1e27] hover:bg-[#ffc478]'>
        <button className=' '>
          <a href="https://maps.app.goo.gl/sooGELmcWdqh7g8V7" target="_blank" rel="noreferrer"> 
            Google Maps
            <FaMapMarkedAlt size={30} className='inline m-2 '/>
          </a>

        </button>
      </div>
    </div>
  );
};

export default Position;
