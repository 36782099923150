import React from "react";
//import Button from "../layouts/Button";
import ServicesCard from "../layouts/ServicesCard";
import { FaHandshakeSimple } from "react-icons/fa6";
import { GiTestTubes } from "react-icons/gi";

import { FaHandHoldingMedical } from "react-icons/fa6";
import { RiHome3Fill } from "react-icons/ri";
import { IoFastFoodSharp } from "react-icons/io5";
import { FaBed } from "react-icons/fa6";
import { LuMonitorSmartphone } from "react-icons/lu";





const Services = () => {

  const icon2 = (
    <FaHandshakeSimple size={35} className="text-[#6A1E27]" />
  );
  const icon3 = (
    <GiTestTubes size={35} className="text-[#6A1E27]" />
  );

  const icon5 = (
    <FaHandHoldingMedical size={35} className="text-[#6A1E27]" />
  );
  const icon6 = (
    <RiHome3Fill size={35} className="text-[#6A1E27]" />
  );
  const icon7 = (
    <IoFastFoodSharp size={35} className="text-[#6A1E27]" />
  );
  const icon8 = (
    <FaBed size={35} className="text-[#6A1E27]" />
  );
  const icon9 = (
    <LuMonitorSmartphone size={35} className="text-[#6A1E27]" />
  );

  const c = {
    'c1': 'Notre centre est conventionné avec tous les organismes payeurs : CNOPS, CNSS, FAR, CFE… Le service de comptabilité se charge de toutes les démarches administratives afin de faciliter la prise en charge du patient.',

    'c2': "Afin d’éviter des ponctions supplémentaires et inutiles, les analyses biologiques sont prélevées directement au moment du branchement, à partir du centre. Le patient n’est pas tenu de se déplacer ni d’effectuer ses prélèvements biologiques en dehors de la séance.",



    'c4': "Une pharmacie est conventionnée avec notre centre afin d’aider le patient dans ses démarches d’obtention de l’erythropoietine. Elle est également disponible pour livrer les médicaments aux patients qui le souhaitent.",
    
    'c5': "Les patients provenant d’autres pays ou d’autres villes sont les bienvenus. En fonction des pays une demande de prise en charge peut être effectuée de telle sorte que le patient n’ait pas à régler ses séances.",

    'c6': "Au cours de sa séance de dialyse, le patient reçoit un repas préparé sur place, le jour même, et à base de produits frais. Il peut également, et s’il le préfère apporter son propre repas que l’équipe para médicale se fera un plaisir de réchauffer.",

    'c7': "Lits ou fauteuils électriques à plusieurs positions pour assurer votre confort.",

    'c8': "Télévisions avec connexion satellite et un réseau Wifi pour accéder à Internet.",

     
  }

  return (
    <div id="services" className="min-h-screen flex flex-col justify-center items-center lg:px-32 px-5 pt-24 lg:p-2 bg-gradient-to-b from-[#ffdfb5] to-[#ffdfb5] lg:pb-10">
      <div className="flex flex-col items-center">
        <h1 className="text-4xl font-bold text-center lg:text-start text-red-950">
          Nos Services
        </h1>
      </div>
      <div className="pt-8 w-full max-w-6xl mx-auto">
        <div className="flex flex-wrap justify-center gap-5 lg:gap-8">
          <ServicesCard icon={icon2} title="CONVENTIONS" content={c.c1} />
          <ServicesCard icon={icon3} title="LABORATOIRE D’ANALYSES MÉDICALES" content={c.c2} />
          <ServicesCard icon={icon5} title="PHARMACIE" content={c.c4} />
          <ServicesCard icon={icon6} title="ACCUEIL DES PATIENTS PROVENANT D’AUTRES VILLES OU PAYS" content={c.c5} />
          <ServicesCard icon={icon7} title="REPAS À BASE DE PRODUITS FRAIS" content={c.c6} />
          <ServicesCard icon={icon8} title="LITS CONFORTABLES" content={c.c7} />
          <ServicesCard icon={icon9} title="TV ET WIFI" content={c.c8} />
        </div>
      </div>
    </div>
  );
  
};

export default Services;
