import React from "react";

const ServicesCard = ({ icon, title ,content}) => {

  const shadowStyle = {
    boxShadow: '0 4px 6px -1px rgba(8, 145, 178, 0.1), 0 2px 4px -1px rgba(8, 145, 178, 0.06)', 
    filter: `drop-shadow(2px 4px 6px black)`
  };

  return (
    <div className="group flex flex-col items-center text-center gap-2 w-full lg:w-1/3 p-5 bg-[#6A1E27] rounded-lg cursor-pointer lg:hover:-translate-y-6 transition duration-300 ease-in-out" style={shadowStyle}>
      <div className="bg-[#ffedd5] p-3 rounded-full transition-colors duration-300 ease-in-out group-hover:bg-[#ffedd5]">
        {icon}
      </div>
      <h1 className="font-semibold text-lg text-[#ffedd5] group-hover:text-[#ffedd5] ">{title}</h1>
      <p className="text-white">
        {content}
      </p>
    </div>
  );
};

export default ServicesCard;
