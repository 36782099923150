import React from "react";


const CentreCards = ({ img, headlines, summary, onClick }) => {
  return (
    <div className="w-full lg:w-1/4 p-2 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] space-y-2 rounded-lg cursor-pointer hover:scale-105 transition duration-300 ease-in-ou" onClick={onClick}>
      <img className="h-64 md:h-200 lg:h-200 w-full rounded-lg" src={img} alt="" />
      <h2 className="text-lg text-center font-semibold text-black">{headlines}</h2>
      <p className="text-center text-sm text-red-950">{summary}</p>
    </div>
  );
};


export default CentreCards;
