import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import * as emailjs from 'emailjs-com';
import {AiOutlineGoogle,AiOutlineWhatsApp} from 'react-icons/ai'
import { FaEnvelope, FaFacebook} from 'react-icons/fa';
import logoKilyati from '../assets/logoOriginal.jpg'
import {FaPhone} from 'react-icons/fa'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FiSmartphone } from "react-icons/fi";


const Contact = () => {

  const [showForm, setShowForm] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = ()=>{
    setShowForm(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !phoneNumber || !email || !message) {
      toast.error("Veuillez remplir tous les champs du formulaire", {
        position: "top-center"
      });
      return; 
    }
    const templateParams = {
      from_name: `${firstName} ${lastName}, ${email}`,
      phone_number: phoneNumber,
      email: email,
      message: message
    };

    emailjs.send('service_re2y7ts', 'template_1m5mybj', templateParams, 'VSqcin-p5EoLX2ZCS')
    .then((response) => {
      toast.success("Message Envoyé", {
        position: "top-center",
      });   
      setShowForm(false);
   
    })
    .catch((error) => {
      toast.error("désolé, une erreur s'est produite lors de l'envoi de votre message", {
        position: "top-center"
      });
      setShowForm(false);

    });
    
  };

  

  return (
    <>
      {showForm && (<div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 z-50 ">
      <div className="relative text-white flex mx-auto my-8 ">
      <div className="hidden md:block text-[#6a1e27] bg-[#ffdfb5] w-[380px] p-3 flex-col rounded-tl-3xl rounded-bl-3xl">
  <div className="flex items-center gap-3">
    <img src={logoKilyati} alt="logo kilyati" className="h-[50px] rounded-3xl shadow-lg ring-1 ring-[#d2bd5e]" />
    <h3 className="font-bold">Centre d'Hemodialyse Kilyati</h3>
  </div>
  <p className="mt-8">contactez-nous aux numéros suivants :</p>
  <div className="mt-5 flex gap-4">
    <FaPhone size={30}/>
    <span>05 28 33 17 02</span>
  </div>
  <div className="mt-5 flex gap-4">
    <FiSmartphone size={30}/>
    <span>06 74 80 26 58</span>
  </div>
  <div className="mt-5 flex gap-4">
    <FaEnvelope size={30}/>
    <span>ckd.rendezvous@gmail.com
    </span>
  </div>
  <br />
  <br />
  <p className="mt-7">ou contactez-nous sur nos réseaux sociaux ci-dessous:</p>
  <div className="flex gap-4 items-center mt-auto justify-center">
    <FaFacebook size={30} className="hover:text-red-600 cursor-pointer"/>
    <AiOutlineGoogle size={38} className="hover:text-red-600 cursor-pointer"/>
    <AiOutlineWhatsApp size={30} className="hover:text-red-600 cursor-pointer"/>
  </div>
</div>


        <div className="form w-full md:w-[400px] shadow-xl overflow-hidden z-[100] relative cursor-pointer snap-start shrink-0 py-5 px-6 bg-[#ffffff] r flex flex-col items-center justify-center gap-3 transition-all duration-300 rounded-tr-3xl rounded-br-3xl">
        <button onClick={handleClose} className="p-2 focus:outline-none focus:scale-110 font-semibold text-xs py-2 hover:scale-110 transition-all hover:transiton text-[#6a1e27] bg-[#ffdfb5] shadow-[#6a1e27] shadow-lg absolute top-[10px] right-[10px] rounded-md hover-[#6a1e27] "><MdOutlineClose size={20} />
                </button>
          <div className="capitalize">
            <p className="text-2xl text-slate-900 font-bold pb-5">Comment pouvons-nous vous aider ?</p>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <div className="flex flex-col items-start w-full">
                <label for="First_name" className="text-sm text-slate-900 font-semibold">Nom:</label>
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Votre Nom" className="w-full py-px pl-0 bg-transparent outline-none focus:ring-0 border-0 border-b-2 border-[#7F3D27] placeholder:text-slate-900 focus:outline-none text-slate-900 placeholder:text-xs" />
              </div>
              <div className="flex flex-col items-start w-full">
                <label for="Last_name" className="text-sm text-slate-900 font-semibold">Prénom:</label>
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Votre Prénom" className="w-full py-px pl-0 bg-transparent outline-none focus:ring-0 border-0 border-b-2 border-[#7F3D27] placeholder:text-slate-900 focus:outline-none text-slate-900 placeholder:text-xs" />
              </div>
              <div className="flex flex-col items-start w-full">
                <label for="Phone_number" className="text-sm text-slate-900 font-semibold">Numéro de téléphone:</label>
                <input type="tel" value={phoneNumber}  onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Votre Numéro" onKeyDown={(e) => {if (e.key === 'Backspace' || e.key === 'Delete') {return;} if (!/^\d$/.test(e.key))  {e.preventDefault();}}} className="w-full py-px pl-0 bg-transparent outline-none focus:ring-0 border-0 border-b-2 border-[#7F3D27] placeholder:text-slate-900 focus:outline-none text-slate-900 placeholder:text-xs" />
              </div>
              <div className="flex flex-col items-start w-full">
                <label for="email" className="text-sm text-slate-900 font-semibold">E-mail:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Votre E-mail" className="w-full py-px pl-0 bg-transparent outline-none focus:ring-0 border-0 border-b-2 border-[#7F3D27] placeholder:text-slate-900 focus:outline-none text-slate-900 placeholder:text-xs" />
              </div>
              <div className="flex flex-col items-start w-full">
                <label for="password" className="text-sm text-slate-900 font-semibold">Message:</label>
                <textarea type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Ecrivez votre message..." className="w-full py-px pl-0 bg-transparent outline-none focus:ring-0 border-0 border-b-2 border-[#7F3D27] placeholder:text-slate-900 focus:outline-none text-slate-900 placeholder:text-xs" />
              </div>
             
              <div className="inline-flex gap-5 mx-auto">
                <button type="submit" className="px-6 focus:outline-none focus:scale-110 font-semibold text-xs py-2 rounded-[5px] hover:scale-110 transition-all hover:transiton text-[#6a1e27] bg-[#ffdfb5] shadow-[#6a1e27] shadow-lg">Envoyer
                </button>

              </div>
            </form>
                
          </div>
        </div>
      </div>
    </div>)} </>
    
  );
};

export default Contact;
